import React from 'react'

import Layout from '../components/Layout'
import SectionHeaderImage from '../components/common/SectionHeaderImage'
import image_background from '../images/solutions/fiscal.png'
import FooterContact from './../components/FooterContact'
import PageContentSectorFinanciero from '../components/Solutions/Pages/SectorFinanciero'

const FinancialSector = props => (
  <Layout
    title="Sector financiero"
    description="Nuestros servicios son especializados y enfocados a la Auditoría del cumplimiento normativo en la Prevención de Operaciones con Recursos de Procedencia Ilícita y Financiamiento al Terrorismo para aquellas entidades cuya actividad principal sean catalogada como Actividades Vulnerables"
    {...props}
  >
    <SectionHeaderImage image={image_background} position="90% 0" />
    <PageContentSectorFinanciero />
    <FooterContact />
  </Layout>
)

export default FinancialSector
