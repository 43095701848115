import React from 'react'
import _times from 'lodash/times'

import { FormattedMessage } from 'react-intl'
import { Col, Row } from 'react-styled-flexboxgrid'
import { TitleSection } from '../../common/Index.styled'
import Section from '../../common/Section'
import { ContractButtonSmall } from '../SolutionDetail.styled'

const PageContentSectorFinanciero = () => {
  return (
    <Section>
      <TitleSection>
        <FormattedMessage id="solutions.financial-sector.title" />
      </TitleSection>
      <div>
        <p>
          <FormattedMessage id="solutions.financial-sector.content.p1" />
        </p>
        <h2>
          <FormattedMessage id="solutions.financial-sector.content.subtitle" />
        </h2>
        <Row xs={12}>
          <Col xs={12} lg={6}>
            <ul>
              {_times(13, Number).map((value, key) => (
                <li key={key}>
                  <FormattedMessage
                    id={`solutions.financial-sector.content.list.item${value}`}
                  />
                </li>
              ))}
            </ul>
          </Col>
          <Col xs={12} lg={6}>
            <ul>
              {_times(8, Number).map((value, key) => (
                <li key={key}>
                  <FormattedMessage
                    id={`solutions.financial-sector.content.list.item${value +
                      13}`}
                  />
                </li>
              ))}
            </ul>
          </Col>
        </Row>
      </div>
      <ContractButtonSmall to="/contacto/" small>
        <FormattedMessage id="element.button_hire" />
      </ContractButtonSmall>
    </Section>
  )
}

export default PageContentSectorFinanciero
